import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
         fill="#ccd6f6" stroke="none">
        <path d="M188 444 c-3 -5 3 -22 13 -39 16 -25 19 -27 19 -9 0 24 -25 61 -32
48z"/>
        <path d="M270 319 c0 -84 3 -119 10 -109 5 8 10 23 10 33 0 10 16 49 35 87
l35 70 50 0 c79 0 58 17 -27 22 -43 3 -86 9 -95 13 -17 7 -18 -3 -18 -116z"/>
        <path d="M38 373 c-23 -26 -33 -117 -18 -154 15 -35 54 -49 77 -26 9 9 17 41
20 80 5 60 4 67 -20 91 -31 31 -39 32 -59 9z"/>
        <path d="M415 270 c-20 -33 -35 -64 -33 -68 15 -35 63 -112 69 -112 4 0 4 12
0 28 -4 15 -5 67 -1 117 3 50 5 91 4 93 -1 2 -18 -24 -39 -58z"/>
        <path d="M192 155 c-13 -28 -82 -75 -112 -75 -12 0 -32 11 -45 25 l-25 24 0
-51 0 -51 77 7 c42 3 85 6 97 6 19 0 21 6 21 70 0 39 0 70 -1 70 0 0 -6 -11
-12 -25z"/>
        <path d="M281 91 c-19 -35 -13 -49 24 -53 38 -4 44 2 20 22 -8 7 -15 18 -15
26 0 22 -18 26 -29 5z"/>
      </g>
    </g>
  </svg>
);

export default IconLogo;
